<template>
  <div class="v-step-final-contract">
      <transition name="fade" appear>
          <div class="v-step-final-contract-block area center">
            <div class="wrapper-notifications success">
                <p>Договор успешно оформлен</p>
            </div>
            <div class="v-step-final-contract-img">
                    <img src="@/assets/img/svg/statement-final.svg" alt="">
                </div>
                <div class="v-step-final-contract-text">
                    <p>Договор оформлен. Статус договора и баланс на счете клиент может узнать в своем личном кабинете, на сайте фонда.</p>
                    <p>Ссылка для первого входа в личный кабинет будет направлена клиенту на электронную почту в течение 5 дней.</p>
                </div>
                <div class="v-step-final-contract-print">
                    <div class="v-step-final-contract-print-block">
                        <button 
                            class="btn-small btn-text-icon link-inline"
                        >
                        <span class="btn-icon">
                                <svg>
                                <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                            </svg>
                        </span>
                        <span>Распечатать договор-оферту</span>
                        </button>
                        <button 
                            class="btn-small btn-text-icon link-inline"
                        >
                        <span class="btn-icon">
                                <svg>
                                <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                            </svg>
                        </span>
                        <span>Распечатать заявление для бухгалтерии</span>
                        </button>
                        <button 
                            class="btn-small btn-text-icon link-inline"
                        >
                        <span class="btn-icon">
                                <svg>
                                <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                            </svg>
                        </span>
                        <span>Распечатать заявление на налоговый вычет</span>
                        </button>
                        <button 
                            class="btn-small btn-text-icon link-inline"
                        >
                        <span class="btn-icon">
                                <svg>
                                <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                            </svg>
                        </span>
                        <span>Распечатать памятку клиента</span>
                        </button>
                        <button 
                            class="btn-small btn-text-icon link-inline"
                        >
                        <span class="btn-icon">
                                <svg>
                                <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                            </svg>
                        </span>
                        <span>Распечатать платежное поручение</span>
                        </button>
                    </div>
                </div>
                <div class="v-step-final-contract-btn">
                    <router-link 
                        to="/home" 
                        tag="button"
                        class="btn-big btn-text primary"
                    >
                        Вернуться на главную
                    </router-link>
                </div>  
        </div>
      </transition>      
  </div>
</template>

<script>
export default {
    name: "v-step-final-contract"
}
</script>
